<template>
  <Item :item="item" :parent="parent">
    <label>
      <span class="sr-only">{{ label }}</span>
      <input
        :placeholder="item.settings.placeholder"
        :type="item.settings.inputType"
        class="
          p-4
          w-full
          bg-white
          rounded
          focus:ring-gray-200
          outline-none
          border border-black border-opacity-25
        "
      />
    </label>
  </Item>
</template>

<script>
import itemMixin from '@/mixins/item'

export default {
  name: 'TextField',

  components: {
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>
